import { Post } from "@prmf/site-data";
import { generatePath, NavLink, useMatch } from "@remix-run/react";
import { twJoin, twMerge } from "tailwind-merge";
import BgImage from "./BgImage";
import ReadingTime from "./ReadingTime";

type NewsItemProps = { post: Post; featured?: boolean };
const NewsItem: React.FC<NewsItemProps> = ({ post, featured }) => {
  const isList = useMatch("/news/:category/:slug") === null;
  const isFeatured = isList && featured;
  // remix loader sends JSON and stringified date
  const postDate = new Date(post.date);
  return (
    <NavLink
      to={generatePath("/news/:category/:slug", {
        category: post.category,
        slug: post.slug,
      })}
      className={twJoin(isFeatured && "lg:col-span-2 lg:row-span-2")}
    >
      <article className="text-left max-md:grid max-md:grid-cols-2 max-md:gap-4">
        <header>
          <BgImage
            className="relative aspect-square rounded bg-zinc-300"
            src={post.media[0]}
          >
            {!["authors", "case-studies"].includes(post.category) &&
              post.author && (
                <BgImage
                  src={post.author.picture}
                  className={twJoin(
                    "!absolute right-3 top-3",
                    "rounded-full border border-white bg-zinc-400",
                    isFeatured ? "h-8 !w-8" : "h-8 !w-8",
                  )}
                />
              )}
          </BgImage>
          <div className="flex justify-between py-1 text-xs text-neutral-400 max-md:hidden">
            <h5>{post.category}</h5>
            <time>{postDate.toLocaleDateString("en-GB")}</time>
          </div>
        </header>
        <div
          className={twJoin(
            "my-2 leading-snug text-neutral-700",
            isFeatured ? "text-lg" : "text-sm",
          )}
        >
          <h3 className="font-bold">{post.title}</h3>
          <h4 className="font-normal">{post.subtitle}</h4>
          <div className="mt-2 space-y-1 text-gray-500 md:hidden">
            <h5 className="text-xs">
              Added under <span className="underline">{post.category}</span>
            </h5>
            <time className="block text-xs">
              Posted on {postDate.toLocaleDateString("en-GB")}
            </time>
            <ReadingTime text={post.text} className="py-2" />
            <small className="underline">Find out more</small>
          </div>
        </div>
        <div
          className={twJoin(
            "leading-snug",
            "max-md:hidden",
            "text-xs",
            isFeatured && "lg:text-base",
          )}
        >
          {post.text.split("\n")[0]}
        </div>
        <ReadingTime text={post.text} className="py-2 max-md:hidden" />
      </article>
    </NavLink>
  );
};

type Props = {
  className?: string;
  posts: Post[] | undefined;
};

const NewsItems: React.FC<Props> = ({ posts, className }) => {
  if (!posts || !posts.length) {
    return (
      <div className={twMerge("my-10", className)}>
        <h2 className="mx-auto text-gray-500">
          No articles found for this criteria
        </h2>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        "grid gap-10",
        "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        className,
      )}
    >
      {posts.map((p, idx) => (
        <NewsItem key={idx} post={p} featured={posts.length > 4 && idx === 0} />
      ))}
    </div>
  );
};

export default Object.assign(NewsItems, { Child: NewsItem });
