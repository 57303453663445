import { EyeIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import readingTime from "reading-time";

type Props = {
  text: string;
  className?: string;
};

const ReadingTime: React.FC<Props> = (props) => {
  const { minutes } = readingTime(props.text);
  const text = `${minutes} min read`;
  return (
    <div className={clsx("flex items-center gap-2 text-xs", props.className)}>
      <EyeIcon className="h-4 w-4" />
      <span>{text}</span>
    </div>
  );
};

export default ReadingTime;
